:root {
	--alt: #918467;
}

.projectanimate article {
	animation-name: projects;
	animation-duration: 500ms;
	animation-timing-function: ease-out;
	animation-iteration-count: 1;
	animation-fill-mode: both;
}

		@keyframes projects {
			0% {
				transform: scale(0.8);
				opacity: 0;
			}

			100% {
				transform: scale(1.0);
				opacity: 1;
			}
		}

	.projectanimate article:nth-child(1) { animation-delay: .1s }
	.projectanimate article:nth-child(2) { animation-delay: .2s }
	.projectanimate article:nth-child(3) { animation-delay: .3s }
	.projectanimate article:nth-child(4) { animation-delay: .4s }
	.projectanimate article:nth-child(5) { animation-delay: .5s }
	.projectanimate article:nth-child(6) { animation-delay: .6s }
	.projectanimate article:nth-child(7) { animation-delay: .7s }
	.projectanimate article:nth-child(8) { animation-delay: .8s }

.topnav {
	ul {
		list-style-type: none;
		display: flex;
		flex-flow: row wrap;
		justify-content: left;
		align-items: center;
		margin-top: 30px;
		margin-bottom: 60px;

		@media only screen and (max-width: 606px) {
			justify-content: center;
			margin-bottom: 30px;
			padding: 0 30px;
		}
	}

	li {
		font-family: "metallophile-sp8", Helvetica, sans-serif;
		font-weight: 400;
		font-size: 1rem;
		background: #fff;
		color: #222;
		padding: 0px 10px;
		border-radius: 10px;
		text-transform: none;
		letter-spacing: .025rem;
		margin: 5px 10px 5px 0px;
		border: 0px solid #fff;

		@media only screen and (max-width: 607px) {
			margin: 10px 5px 0px 5px;
			padding: 0px 5topnpx;
		}

		@media only screen and (max-width: 430px) {
			margin: 10px 10px 0px 0px;
			padding: 0px;
		}

		&:hover {
			background: #222;
			color: #fff;
		}

		a {
			color: #222;
			text-decoration: none;
			
			padding: 5px 15px;

			&:hover {
				color: #fff;
			}
		}
	}
}

.work {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	// padding: 30px 0px;

	@media only screen and (max-width: 805px) {
		flex-direction: column;
		justify-content: center;
		// text-align: center;
	}
}


.stackedwork {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	padding-top: 30px;
	align-items: left;

	@media only  screen and (max-width: 900px) {
		align-items: center;
	}
}

 .workdetails {
 	padding: 0px 30px;
 	text-align: center;
 }

 .workdetails p {
 	padding-top: 10px;
 }

 .workdetails h2 {
 	text-transform: uppercase;
 	font-size: 1.5rem;
 }

.details {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-around;

	// @media only screen and (max-width: 700px) {
	// 	margin-top: 30px;
	// 	flex-flow: row nowrap;
		
	// }

	@media only screen and (max-width: 560px) {
		flex-flow: column nowrap;
	}
}

.left {
	width: 60%;
}

.right {
	text-align: left;

	p {
		margin-top: 30px;
	}
}

.left,
.right {
	@media only screen and (max-width: 700px) {
		width: 100%;
	}
}

.center {
	width: 100%;
	padding-bottom: 30px;
}

.backwrap {
	max-width: 1200px;
	display: flex;
	justify-content: space-between;
	flex-flow: row nowrap;
	align-items: center;

	@media only screen and (max-width: 606px) {
		flex-flow: column-reverse nowrap;
	}
}

.worktop {
	display: flex;
	justify-content: space-between;
	flex-flow: row nowrap;

	@media only screen and (max-width: 580px) {
		flex-flow: column-reverse nowrap;
		
	}
}

.topwrap {
	max-width: 1092px;
	margin: 30px 0px;
	padding-bottom: 30px;
	border-bottom: 1px solid #ddd;
	text-align: right;

	@media only screen and (max-width: 465px) {
		border: 0;
	}
}

.btnback {
	font-weight: 400;
	font-family: "metallophile-sp8", Helvetica, sans-serif;
	font-size: 1rem;
	color: var(--alt);
	text-decoration: none;
	letter-spacing: .025rem;
	margin: 30px 0px 60px 0px;

	@media only screen and (max-width: 606px) {
		margin-bottom: 30px;
	}
}

.arrowup {
	height: 20px;
	width: 20px;
	margin-right: 10px;
	margin-bottom: -4px;
}

.dreamcardgrid {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	align-items: center;
	margin: 30px 0px;
}

.dreamcard {
	width: 100%;
	max-width: 320px;
	margin: 15px;
	border-radius: 20px;

	@media only screen and (max-width: 1112px) {
		max-width: 300px;
	}

	@media only screen and (max-width: 1050px) {
		max-width: 280px;
	}

	@media only screen and (max-width: 990px) {
		max-width: 260px;
	}

	@media only screen and (max-width: 930px) {
		max-width: 240px;
	}

	@media only screen and (max-width: 870px) {
		max-width: 220px;
	}

	@media only screen and (max-width: 810px) {
		max-width: 200px;
	}

	@media only screen and (max-width: 750px) {
		max-width: 280px;
	}

	@media only screen and (max-width: 685px) {
		max-width: 250px;
	}

	@media only screen and (max-width: 620px) {
		max-width: 230px;
	}

	@media only screen and (max-width: 580px) {
		max-width: 200px;
	}

	@media only screen and (max-width: 520px) {
		max-width: 175px;
	}

	@media only screen and (max-width: 470px) {
		max-width: 150px;
	}

	@media only screen and (max-width: 425px) {
		max-width: 250px;
	}
}

