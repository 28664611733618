:root {
	--alt: #7f7854;
	--home: #F4F3EE;
	--dark: #333;
}

@import url("https://use.typekit.net/kbn7omj.css");

*	{
	margin: 0; padding: 0; box-sizing: border-box;
}

html {
	 -webkit-font-smoothing: antialiased;
 	 -moz-osx-font-smoothing: grayscale;
 	 -ms-text-size-adjust: 100%;
  	-webkit-text-size-adjust: 100%;
  	box-sizing: border-box;
  	overflow-y: scroll;
  	scroll-behavior: smooth;
}

body {
	font-family: masqualero, Helvetica, Arial, sans-serif;
	font-size: 1.333rem;
	line-height: 2.3rem;
	color: #333;
	margin: 0 auto;
}

.spacing1 { margin-bottom: 30px; }
.spacing2 { margin-bottom: 60px; }
.spacing3 { margin-bottom: 90px; }


/* - -- Typography ----- - -- ---- --- ----- - */

a {
	color: var(--alt);
	transition: 500ms color;
	text-decoration: none;
}

a:hover,
a:active {
	color: #333;
}

h1 {
	font-family: 'masqualero', Helvetica, monospace;
	font-size: 2.5rem;
	line-height: 3.5rem;
	letter-spacing: .05em;
	/*text-transform:  uppercase;*/
	font-weight: 400;
	margin-bottom: 10px;
}

h2 {
	font-family: metallophile-sp8, monospace;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 400;
	letter-spacing: .04rem;
}

h3 {
	font-family: 'masqualero', monospace;
	font-size: 1.5rem;
	line-height: 2.5rem;
	letter-spacing: .05em;
	font-weight: 400;
}

h4 {
	font-family: "masqualero", Helvetica, sans-serif;
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 2.4rem;
	letter-spacing: .1em;
}

small {
	font-size: 1rem;
	font-family:  metallophile-sp8, helvetica, sans-serif;
	font-weight:  400;
	letter-spacing: .04rem;
}

.hero-text,
.site-title h1 {
	font-family:  "masqualero", Helvetica, sans-serif;
	font-size:  2.5rem;
	font-weight:  700;
	color:  #fff;
	mix-blend-mode: difference;
}

.site-title {
	position:  absolute;
	margin: 12rem auto;
	color: #ccc;
	text-align: center;
	width: 96%;
}

.reg small,
.slim small {
	text-transform: uppercase;
}

.work-card-info small,
.home-card-info small {
	letter-spacing: .025em;
}

/* ---- Header/Footer ----  --- - ----  --- -- --*/

.header,
.footer {
	max-width: 1200px;
	margin: 0 auto;
	background: white;
	
	padding-top:  5px;
	margin-bottom: 5px;
	text-align:  center;
}

.footer {
	margin-bottom: 30px;
}

.footer small {
	font-size:  .75rem;
}

.topnav {
	max-width: 1200px;
	padding-top:  10px;
	display: flex;
	justify-content: center;
	flex-flow: row nowrap;
	list-style-type: none;
	/* align-items: center; */
	margin-bottom: -30px;
}

.footernav {
	max-width: 1200px;
	padding-top: 30px;
	display: flex;
	justify-content: center;
	flex-flow: row nowrap;
	list-style-type: none;
	margin-bottom: -20px;
	/* align-items: center; */
}



.topnav a {
	font-family: "metallophile-sp8", monospace;
	font-weight:  400;
	font-size: 1rem;
	text-transform:  uppercase;
	letter-spacing: .075rem;
	color: #333;
	margin-bottom: 15px;
	text-decoration: none;
}

.nav-item-footer a {
	font-family: "metallophile-sp8", monospace;
	font-weight:  400;
	font-size: 1rem;
	letter-spacing:  .05em;
	color: #333;
	text-decoration: none;
	padding-top: 26px;
	margin: 0 10px;
}

.nav-item,
.nav-item-footersocial {
	margin: 0px 15px;
	padding-bottom: 20px;
	transition:  color 700ms  ease-out;
}

.nav-item:hover,
.nav-item:active,
.nav-item-footer a:hover,
.nav-item-footer a:active  {
	color:  var(--alt);
}

.head-wrap,
.foot-wrap {
	max-width: 1200px;
	display: flex;
	/* align-items: center; */
	flex-flow: row nowrap;
	justify-content: space-between;
	padding-top: 5px;
	padding-bottom: 10px;
}


.logo-wrap {
	text-align: center;
}

.foot-social {
	font-family: 'metallophile-sp8', monospace;
	font-weight:  400;
	letter-spacing: .025rem;	
	color: var(--dark);
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 30px;
	margin-top: 30px;
}

.footer .socialicon {
	margin-bottom: -6px;
}

.nav-icons a,
.foot-social a {
	color: #222;
	font-size: 1rem;
	font-weight: 700;
	text-decoration: none;
}

.socialicon {
	width: 20px;
	height: 20px;
	margin-left: 20px;
	margin-bottom: -8px;
	color:  var(--alt);
}

.socialname {
	margin-left: 10px;
}

.nav-icons {
	margin-top: 5px;
	color: var(--dark);
}

.menu-social {
	margin-top: 20px;
}

.menu-social .socialicon {
	margin-left: 0px;
}

/* -- - - - Images ----- -- - - - ------ - -- */

.shadow {
	box-shadow: 0px 0px 15px 5px #ddd;
}

.heroimg {
	width: 100%;
}

.logotype {
	width: 350px;
	height: 25;
	margin-top:  14px;
}

.logomark {
	width: 65px;
	height: auto;

}

.logomark-r {
	width: 65px;
	height: auto;
	display: none;
	margin-top:  10px;
}

.logo-overlay {
	width: 100%;
	max-width: 675px;
	height: auto;
	margin-left: -20px;
}

.splash-img {
	margin-top: -30px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 1200px;
	height: auto;

}

.image {
	width: 100%;
	max-width: 600px;
	
}

.imagewide {
	width: 100%;
	max-width: 690px;
	
}

.imagewider {
	width:  100%;
	max-width:  800px;
}

.stackedimage {
	width: 100%;
	max-width: 600px;
}

/* - - --- - -- Divs --------- - - --- ---- */

.view-container {
	background: var(--home);
	margin: 0 auto;
	padding-top:  30px;
	margin-bottom: 30px;
	padding-bottom: 30px;
}

.container {
	background: #edebe6;
	margin: 0 auto;
	padding: 0px 0px;
}

.content-container {
	max-width: 1200px;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
	margin: 30px auto;
	color: #222;
}

.white-section {
	color: #222;
}

.content {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}

.overlay {
	text-align: center;
	padding-top: 30px;
}

.about-container {
	width:  100%;
	background: #edebe6;
	/*display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items:  center;*/
	margin: 0px auto;
	margin-bottom: 30px;
	padding-top: 0px;
}

.about-container .content-container {
	display:  block;
}

.about-pic {
	background-image: url('../img/about-gallery1.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	min-height: 400px;
	text-align:  center;
	padding:  60px;
}

.about-left {
	padding:  15px 0px;
}

.about-right {
	margin-bottom:  2rem;
	padding:  2rem 0px;
	padding-bottom: 4rem;
}

.about-social {
	color: var(--alt);
	font-family: 'metallophile-sp8', Arial, sans-serif;
	font-weight: 400;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
	margin-top: 10px;
	letter-spacing:  .025rem;
	text-align: center;
}

.about-social a {
	color: var(--alt);
	text-decoration: none;
	margin-left: 10px;
}

.left,
.right {
	width: 65%;
}

.work {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	/*padding: 30px 0px;*/
}

/* Adjust width to work with the works images */
.reg {
	width: 50%;
}

.regmod {
	width: 45%;
}

.slim {
	width: 38%;
}

.slimmer {
	width: 100%;
	padding-left:  30px;
}

.landscape {
	display: flex;
	flex-flow: column nowrap;
	justify-content: column;
	/*padding: 30px 0px;*/
}

.reg,
.slim {
		padding-left: 30px;
	}

/*.reg p,
.slim p,
.slimmer p {
	margin-bottom: 10px;
	margin-top: 30px;
}*/




/* ---- - -- -- Work Grid --- - - --- -- -- - -*/

.work-card {
	width: 30%;
	position: relative;
	margin: 30px 0px;
	background: #222;
}

.work-card a {
	text-transform: uppercase;
	color: white;
}

.work-thumb {
	opacity: 0.5;
	width: 100%;
	
	margin-bottom: -12px;
	box-shadow: 0px 0px 20px #ccc;

	transition: opacity 0.5s ease-out;
}

.home-thumb {
	opacity: 0.5;
	width: 100%;
	height: 100%;
	margin-bottom: -10px;
	box-shadow: 0px 0px 20px #ccc;

	transition: opacity 0.5s ease-out;
}

.work-thumb:hover {
	opacity: 1.0;
}

.home-thumb:hover {
	opacity: 1.0;
}

.work-card-info h2 {
	font-family:  "metallophile-sp8", Helvetica, Arial, serif; 
	text-transform:  none;
	font-size:  1.7rem;
	line-height:  2rem;
	font-weight: 400;
	margin-bottom: 1.5rem;
	letter-spacing: .04rem;
	text-transform: uppercase;
}

.work-card-info {
    font-family:  "masqualero", Helvetica, Arial, serif;
    font-weight:  700;
    text-transform:  none;
	position: absolute;
	line-height: 1.5rem;
	width: 100%;
	top: 35%;
	text-align: center;
	mix-blend-mode: normal;
	z-index: 2;
	opacity: 1.0;

	transition: opacity .5s ease-out;
}

.work-card:hover .work-card-info {
	opacity:  0;
}

.work-mobile-info {
	display: none;
}

/* -- - ---- Responsive --- - - -- -- - - - */

@media only screen and (max-width: 1230px) {
	.container,
	.content,
	.content-container	{
		padding-left: 30px;
		padding-right:  30px;
	}

	.header,
	.head-wrap,
	.footer,
	.foot-wrap  {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media only screen and (max-width:  1200px) {
	.slimmer {
		width:  100%;
		/*padding-top: 30px;*/
	}
}

@media only screen and (max-width: 1112px) {
	
	.footer,
	.content-container,
	.foot-wrap
{
		padding: 0px 30px;
	}



	.view-container {
		padding-top:  30px;
	}
}

@media only screen and (max-width: 1024px) {

	.work-card-info h2 {
		font-size: 1.5rem;
	}
}

@media only screen and (max-width: 949px) {
	.work-card {
		width: 48%;
	}

	.work-card-info h2 {
		font-size:  2rem;
	}


	.work-card-info small {
		font-size:  1.2rem;
	}
}

@media only screen and (max-width:  915px) {
	.logotype {
		display:  none;
	}

	.logomark-r {
		display:  block;
	}

	.about-container {
		flex-flow: column wrap;
	}

	.about-left,
	.about-right {
		width:  100%;
	}

	.about-right {
		text-align:  center;
	}
}



@media only screen and (min-width:  880px) {
	.site-title {
		display: none;
	}
}

@media only screen and (max-width: 880px) {
	.work-card {
		width: 48%;
	}

}

@media only screen and (max-width:  830px) {
	.site-title {
		margin-top: 11rem;
		margin-left:  -0.5rem;
	}
}

@media only screen and (max-width: 805px) {
	.left,
	.right,
	.reg,
	.slim,
	.slimmer {
		width: 100%;
	/*	padding-top: 30px;*/
		padding-left:  0px;
	}

	.work-card-info h2 {
		font-size: 1.6rem;
	}

	.work,
	.landscape {
		flex-direction: column;
		justify-content: center;
		/*text-align: center;*/
	}

	.about-container .left,
	.about-container .right {
		width: 100%;
	}

	.about-social {
		margin-top: 90px;
		flex-flow: row nowrap;
	}

	.heroimg {
		display: none;
	}

	.heroimgwrap {
		text-align: center;
		display: block;
		position: absolute;
		top: 150px;
	}

	.heroimgmobile {
		opacity: .2;
		width: 90%;
	}
}

@media only screen and (max-width:  775px) {
	.site-title {
		margin-top: 10rem;
		margin-left: -.5rem;
	}
}

@media only screen and (max-width: 720px) {
	.nav-item {
		margin-right:  0px;
		padding-left:  15px;
	}

	.site-title {
		margin-top: 9rem;
		margin-left: -.5rem;
	}
}

@media only screen and (min-width: 700px) {
	.mobiletitle {
		display: none;
	}
}

@media only screen and (max-width: 700px) {
	.site-title {
		margin-top: 8rem;
		margin-left: -.5rem;
	}

	.socialname {
		display: none;
	}

	.footer p {
		text-align:  center;
	}

	.foot-social {
		justify-content: center;
	}

	.desktoptitle {
		display: none;
	}

	.work-card-info h2  {
		font-size: 1.5rem;
	}

	.work-card-info small  {
		font-size: 1.2rem;
	}
}

@media only screen and (max-width: 674px) {
	.nav-icons a {
		margin-left: -10px;
	}

	.about-pic {
		padding:  30px;
	}

	.about-social {
		flex-direction: column;
		margin: 30px 0px;
	}

	.hero-text {
		font-size: 2rem;
		line-height: 3rem;
	}
}

@media only screen and (max-width: 607px) {
	body {
		font-size: 1.2rem;
		line-height: 2rem;
	} 

	h1 {
		font-size: 2.8rem;
	}

	.content-container {
		flex-direction: column;
	}

	.work-card {
		width: 100%;
		
	}

	.work-card-info h2  {
		font-size: 2.3rem;
	}

	.work-thumb {
		    margin-bottom: -9px;
	}
}

@media only screen and (max-width: 606px) {
	.header,
	.display {
	 	display: none; 
	}

	.site-title {
		margin-top:  4rem;
		margin-left:  -1rem;
	}

	.work-header {
		text-align: center;
		padding-bottom:  30px;
	}

	h1 {
		margin-top: 30px;
	}
}

@media only screen and (min-width: 560px) {
/*	.reg p,
	.slim p,
	.slimmer p {
		margin-bottom: 10px;
		margin-top: 30px;
	}*/
}

@media only screen and (max-width: 585px) {

	h2 {
		font-size: 1.75rem;
	}
}

@media only screen and (max-width:  550px) {
	.site-title {
		margin-top:  3rem;
		margin-left:  -1rem;
	}
}

@media only screen and (max-width: 500px) {
	.work-card-info h2 {
		font-size: 2rem;
	}

	.work-card-info small {
		font-size:  1.2rem;
	}

	.site-title {
		font-size:  2rem;
		margin-top:  2rem;
	}

	.foot-wrap {
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.foot-social {
		padding-top:  30px;
	}

	.foot-logo {
		margin-bottom: 30px;
	}

	.nav-item-footer {
		margin: 0 5px;
	}

	.heroimgmobile {
		width: 125%;
	}

	.nav-item-footer a:hover,
	.nav-item-footer a:active  {
		color: var(--alt);
	}
}

@media only screen and (max-width: 465px) {
	.site-title {
		margin-top: 0.5rem;
	}

	.site-title h1 {
		line-height:  3rem;
	}

	.slimmer,
	.slim,
	.reg {
		padding:  10px 30px;
		text-align:  center;
	}

	.slim h2, .slimmer h2, .reg h2 {
		font-size:  1.5rem;
		text-transform:  uppercase;
	}
}

@media only screen and (max-width: 450px) {
.site-title {
	margin-left:  1rem;
}

	.content {
		padding-left: 0;
		padding-right:  0;
	}

	.content p {
		padding-left:  30px;
		padding-right: 30px;
	}

	.heroimgmobile {
		width: 200%;
		margin-top: -75px;
		margin-left: -150px;
	}
}

@media only screen and (max-width: 400px) {
	.work-card-info h2 {
		font-size: 1.6rem;
	}

	.site-title h1 {
		font-size:  2rem;
		line-height: 2.7rem;
	}

	.site-title {
		margin-top: 2rem;
	}
}

@media only screen and (max-width: 380px) {


	.work-card-info small {
		font-size: .8rem;
	}
}

@media only screen and (max-width:  375px) {
	.site-title {
		margin-top:  0;
	}
}

@media only screen and (max-width: 360px) {
	/*.nav-item-footer {
		margin-left: 10px;
		margin-right: 10px;
	}*/
}